import React from 'react'

const Reset = () => {
    return (
        <div>

        </div>
    )
}

export default Reset
