import React from 'react';
import UserContext from './Helpers/UserContext';
import { useQuery } from '@apollo/client';
import { isLoggedIn } from './Helpers/IsLoggedIn';
import { CURRENT_USER, GET_CONFIG } from './GraphQL/Queries';
import Session from './Helpers/Session';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Loading } from './Components/Loading';
import { CurrentUser } from './types/CurrentUser';
import { useJsApiLoader } from "@react-google-maps/api";

import Register from './Pages/Auth/Register';
import Login from './Pages/Auth/Login';
import Activate from './Pages/Auth/Activate';
import Forgot from './Pages/Auth/Forgot';
import Reset from './Pages/Auth/Reset';
import Logout from './Pages/Auth/Logout';

import Dashboard from './Pages/MenuPages/Dashboard';
import SendPackage from './Pages/MenuPages/Dashboard/SendPackage';
import FindCourier from './Pages/MenuPages/Dashboard/FindCourier';
import Orders from './Pages/MenuPages/Dashboard/Orders';
import Notifications from './Pages/MenuPages/Dashboard/Notification';
import Account from './Pages/MenuPages/Account';
import TermAndPolicy from './Pages/MenuPages/Account/Terms&Policy';
import CustomerSupport from './Pages/MenuPages/Account/CustomerSupport';
import Wallet from './Pages/MenuPages/Wallet';
import FundAccount from './Pages/MenuPages/Wallet/FundAccount';
import Withdraw from './Pages/MenuPages/Wallet/Withdraw';
import Transaction from './Pages/MenuPages/Wallet/Transaction';
import DeliveryDetails from './Pages/MenuPages/Dashboard/DeliveryDetails';
import VoiceCall from './Pages/MenuPages/Dashboard/VoiceCall';
import ChatWithCourier from './Pages/MenuPages/Dashboard/ChatWithCourier';
import Faqs from './Pages/MenuPages/Account/Faqs';

const App: React.FC = () => {
    const [user, setUser] = React.useState<CurrentUser | null>(null);
    const [config, setConfig] = React.useState('{}');
    const loggedIn = isLoggedIn();

    useQuery(GET_CONFIG, {
        fetchPolicy: 'cache-and-network',
        onCompleted: data => {
            setConfig(data.getConfig);
            Session.setCookie('config', JSON.stringify(data.getConfig));
        }
    });

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
        libraries: ['places', 'maps', 'geocoding', 'marker', 'routes', 'places', 'drawing', 'visualization', 'geometry'],
        region: 'NG',
        language: 'en',
        version: 'weekly'
    })

    const { loading } = useQuery(CURRENT_USER, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setUser(data.currentUser);
            Session.setCookie('user', JSON.stringify(data.currentUser));
            Session.set("user", data.currentUser);
        }
    });

    if (!loggedIn) {
        return (
            <Router>
                <Routes>
                    <Route path={'/register'} element={<Register />} />
                    <Route path={'/activate'} element={<Activate />} />
                    <Route path={'/forgot'} element={<Forgot />} />
                    <Route path={'/reset'} element={<Reset />} />
                    <Route path="*" element={<Login />} />
                </Routes>
            </Router>
        );
    } else {
        if (!isLoaded || loading) return <Loading />
        return (
            <UserContext.Provider value={{ user, setUser, config, setConfig }}>
                <Router>
                    <Routes>
                        <Route path={'/'} element={<Dashboard />} />
                        <Route path={'/dashboard'} element={<Navigate to="/" />} />
                        <Route path={'/logout'} element={<Logout />} />
                        <Route path={'/send-package'} element={<SendPackage />} />
                        <Route path={'/find-courier'} element={<FindCourier />} />
                        <Route path={'/orders'} element={<Orders />} />
                        <Route path={'/account'} element={<Account />} />
                        <Route path={'/notifications'} element={<Notifications />} />
                        <Route path={'/term-and-policy'} element={<TermAndPolicy />} />
                        <Route path={'/customer-support'} element={<CustomerSupport />} />
                        <Route path={'/faqs'} element={<Faqs />} />
                        <Route path={'/wallet'} element={<Wallet />} />
                        <Route path={'/withdraw'} element={<Withdraw />} />
                        <Route path={'/transaction'} element={<Transaction />} />
                        <Route path={'/fund-account'} element={<FundAccount />} />
                        <Route path={'/call'} element={<VoiceCall />} />
                        <Route path={'/chat-with-courier'} element={<ChatWithCourier />} />
                        <Route path={'/delivery-details'} element={<DeliveryDetails />} />
                        <Route path="*" element={<Account />} />
                    </Routes>
                </Router>
            </UserContext.Provider>
        );
    }
};

export default App;
