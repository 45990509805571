import React from 'react';
import Menu from '../../Components/Menu';
import { GET_ORDERS } from "../../GraphQL/Queries";
import { useQuery } from "@apollo/client";
import userPhoto from '../../assets/images/photo.png';
import UserContext from '../../Helpers/UserContext';
import numberToMoney from '../../Helpers/numberToMoney';
import { Link, useNavigate } from 'react-router-dom';
import Session from '../../Helpers/Session';
import { APIResponse } from '../../Helpers/General';
import FormatDate from '../../Helpers/FormatDate';
import { getImage } from '../../Helpers/FileHandling';
import { Loading } from '../../Components/Loading';

const Dashboard = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const navigate = useNavigate();
    const [photo, setPhoto] = React.useState<string | null>(user?.photo || userPhoto);
    const [fullname, setFullname] = React.useState<string>(user?.fullname || '');
    const [phone, setPhone] = React.useState<string>(user?.phone || '');
    const [hideBalance, setHideBalance] = React.useState<boolean>(false);
    const [balance, setBalance] = React.useState<number>(user?.balance || 0.00);
    const [orders, setOrders] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [showNotificationIcon, setShowNotificationIcon] = React.useState(true);

    const toggleBalanceVisibility = () => {
        setHideBalance(!hideBalance);
    };

    React.useEffect(() => {
        // Set a timeout to hide the element after 3 seconds
        const timeoutId = setTimeout(() => {
            setShowNotificationIcon(false);
        }, 3000);

        // Cleanup the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    const { refetch, loading } = useQuery(GET_ORDERS, {
        fetchPolicy: 'network-only',
        variables: {
            offset: offset
        },
        onCompleted(data) {
            setOrders(data.getAllOrders);
        },
        onError(error) {
            Session.showAlert({ str: error.message, type: 'error' });
            APIResponse(error);
            return;
        },
    })
    React.useEffect(() => {
        refetch()
    }, [refetch, offset])
    const getStatusClass = (status: string) => {
        switch (status) {
            case 'Delivered':
                return 'text-success approved';
            case 'Cancelled':
                return 'text-negative cancelled';
            case 'Pending':
                return 'order-pending';
            case 'In Transit':
                return 'order-in-transit';
            case 'Awaiting Pickup':
                return 'awaiting-pickup';
            case 'Courier Arrived for Pickup':
                return 'arrived-for-pickup';
            default:
                return 'text-muted bg-light';
        }
    };
    const shortenStatus = (status: string) => {
        switch (status) {
            case 'Delivered':
                return 'Delivered';
            case 'Cancelled':
                return 'Cancelled';
            case 'Pending':
                return 'Pending';
            case 'In Transit':
                return 'In Transit';
            case 'Awaiting Pickup':
                return 'Awaiting Pickup';
            case 'Courier Arrived for Pickup':
                return 'Pickup Arrival';
            case 'Courier Arrived for Delivery':
                return 'Delivery Arrival';
            default:
                return 'Pending';
        }
    }
    if (loading) return <Loading fullPage={false} message={'Loading...'} timing={false} />
    return (
        <div className='w3-animate-left'>
            <div className='px-3 w-100'>
                <div className='d-flex align-items-center justify-content-between mt-3 py-3'>
                    <div className='d-flex align-items-center justify-content-center'>
                        <img
                            src={photo ? getImage(photo) : userPhoto}
                            alt=""
                            className='rounded-circle'
                            style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                        />
                        <h3 className='w3-center ps-4 my-0'>Hello {fullname.split(" ")[0]}</h3>
                    </div>
                    {showNotificationIcon && (
                        <div className='me-3'
                        // onClick={"#"}
                        >
                            <i className="bi bi-bell fs-3 text-end text-dark"
                                onClick={() => navigate("/notifications")}></i>
                        </div>
                    )}

                </div>
                <div className="bg-primary w3-round-large py-5 px-2">
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-start justify-content-start flex-column'>
                            <div className="text-light">Wallet Balance</div>
                            <div className="d-flex align-items-center justify-content-center">
                                <h2 className="text-center text-light my-0 fw-normal">
                                    {hideBalance ? "*".repeat(numberToMoney(+balance.toFixed(2)).toString().length) : (<span>&#8358;{numberToMoney(+balance.toFixed(2))}</span>)}
                                </h2>
                                <i
                                    className={`bi ${hideBalance ? "bi-eye" : "bi-eye-slash"} ms-2 text-light fs-2`}
                                    onClick={toggleBalanceVisibility}
                                    style={{ cursor: "pointer" }}
                                ></i>
                            </div>
                        </div>
                        <div className="bg-light px-3 py-3 w3-round-large"
                            onClick={() => navigate("/fund-account")}
                        >
                            Fund Wallet
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-between mt-3'>
                    <Link to="/send-package" className=''>
                        <div className='d-flex align-items-center justify-content-between '>
                            <div className='d-flex align-items-start justify-content-center flex-column ps-4 pe-5 py-4 w3-sand w3-round-large dashboard-item'>
                                <img src="./images/3d/open-box.png" alt="" width={"40px"} />
                                <p className='mb-0 fw-bold text-nowrap'>Send Package</p>
                            </div>
                        </div>
                    </Link>
                    <div style={{ width: "15px" }}></div>
                    <Link to={"/orders"} className=''>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-start justify-content-center flex-column ps-4 pe-5 py-4 w3-sand w3-round-large dashboard-item '>
                                <img src="./images/3d/food-delivery.png" alt="" width={"40px"} className='p-1' />
                                <p className='mb-0 fw-bold'>Your Orders</p>
                            </div>
                        </div>
                    </Link>
                </div>
                {orders ? (
                    <div className='mt-3' style={{ marginBottom: "120px" }}>
                        <h3>Recent Orders</h3>
                        {orders.slice(0, 3).map((order: Record<string, any>, index) => (
                            <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large" key={order.id}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                            <img src="./images/3d/open-box.png" alt="" width={"40px"} />
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-0 fw-bold">{order.package_name}</p>
                                            <p className="mb-0 text-muted text-nowrap">On {FormatDate(order.created_at)}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className={`mb-0 p-2 w3-round-xlarge fw-bold fs-6 text-nowrap ${getStatusClass(order.status)}`}>{shortenStatus(order.status)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                ) : (
                    <div className='d-flex h-100 align-items-center justify-content-center' style={{ minHeight: '40svh', marginBottom: "120px" }}>
                        <div className=' fade-overlay'>
                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                <img src="./images/3d/box.png" alt="" width={"100px"} />
                                <p className='mb-0 fw-bold text-muted text-center text-wrap mt-3 w-75' >You currently do not have any pending deliveries.</p>
                            </div>
                        </div>
                    </div>

                )}



            </div>
            <Menu />
        </div>
    );
}

export default Dashboard
