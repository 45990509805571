import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../Helpers/UserContext';
import Session from '../../../Helpers/Session';
import QB, { GetMessagesResult, QBChatNewMessage, QBChatXMPPMessage, QBConfig } from "quickblox/quickblox";
import { QBConfig as QB_CONFIG } from '../../../config/QB_config';
import { Loading } from '../../../Components/Loading';

interface Message {
    id: string;
    text: string;
    timestamp: Date;
    isUser: boolean;
}

const ChatWithCourier: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [messages, setMessages] = useState<Message[]>([]);
    const courier = Session.get("selectedCourier")[0];
    const [inputMessage, setInputMessage] = useState('');
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const QuickBlox = JSON.parse(Session.getCookie("config")).quickblox;
    const [dialogId, setDialogId] = useState<string>('');
    const navigate = useNavigate();

    let courierChatDetails: any;
    try {
        courierChatDetails = JSON.parse(courier.chat_details);
    } catch (error) {
        courierChatDetails = courier.chat_details;
    }

    const userChatAuth = {
        login: user.email,
        password: QuickBlox.usersPassword,
    };

    const [isUserAuthorized, setUserAuthorized] = useState(false);
    const [isSDKInitialized, setSDKInitialized] = useState(false);

    const prepareSDK = async (): Promise<void> => {
        if ((window as any).QB === undefined) {
            if (QB !== undefined) {
                (window as any).QB = QB;
            } else {
                let QBLib = require('quickblox/quickblox.min');
                (window as any).QB = QBLib;
            }
        }

        const APPLICATION_ID = QB_CONFIG.credentials.appId;
        const AUTH_KEY = QB_CONFIG.credentials.authKey;
        const AUTH_SECRET = QB_CONFIG.credentials.authSecret;
        const ACCOUNT_KEY = QB_CONFIG.credentials.accountKey;
        const CONFIG: QBConfig = {};

        QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY, CONFIG);
    };

    useEffect(() => {
        if (!isSDKInitialized) {
            prepareSDK().then(() => {
                QB.createSession(userChatAuth, async (errorCreateSession: any, session: any) => {
                    if (errorCreateSession) {
                        console.log('Create User Session has error:', JSON.stringify(errorCreateSession));
                    } else {
                        console.log(session);
                        const userId: number = session.user_id;
                        const password: string = session.token;
                        const paramsConnect = { userId, password };

                        QB.chat.connect(paramsConnect, async (errorConnect: any) => {
                            if (errorConnect) {
                                console.log('Cannot connect to chat server: ', errorConnect);
                            } else {
                                setSDKInitialized(true);
                                setUserAuthorized(true);

                                const dialogParams = {
                                    type: 3,
                                    occupants_ids: [+courierChatDetails.id]
                                };
                                QB.chat.dialog.create(dialogParams, async (error: any, dialog: any) => {
                                    if (!error) {
                                        console.log('Dialog has been created: ', dialog);
                                        setDialogId(dialog._id);
                                        fetchChatHistory(dialog._id);
                                    }
                                });
                            }
                        });
                    }
                });
            }).catch(e => console.log('init SDK has error: ', e));
        }
    }, []);

    const fetchChatHistory = (dialogId: string) => {
        const params = {
            chat_dialog_id: dialogId,
            sort_desc: 'date_sent',
            limit: 100,
            skip: 0
        };

        QB.chat.message.list(params, (error, messages: GetMessagesResult | any) => {
            if (error) {
                console.log('Error fetching chat history:', error);
            } else {
                console.log('Chat history:', messages);
                const formattedMessages = messages.items.map((msg: any) => ({
                    id: msg._id,
                    text: msg.message,
                    timestamp: new Date(msg.date_sent * 1000),
                    isUser: msg.sender_id === user.chat_details.id
                }));
                setMessages(formattedMessages.reverse());
            }
        });
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    // Subscribe to real-time messages
    useEffect(() => {
        QB.chat.onMessageListener = (userId, message: QBChatXMPPMessage) => {
            console.log('Received message:', message)
            const incomingMessage: Message = {
                id: message.id,
                text: message.body || "",
                timestamp: new Date(parseInt(message.extension.date_sent) * 1000),
                isUser: message.recipient_id === user.id,
            };
            setMessages((prevMessages) => [...prevMessages, incomingMessage]);
        };
    }, []);

    const handleSendMessage = () => {
        if (inputMessage.trim()) {
            const newMessage: Message = {
                id: Date.now().toString(),
                text: inputMessage,
                timestamp: new Date(),
                isUser: true,
            };
            setMessages([...messages, newMessage]);
            setInputMessage('');

            const qbMessage: QBChatNewMessage = {
                type: 'chat',
                body: newMessage.text,
                extension: {
                    save_to_history: 1,
                    dialog_id: dialogId,
                },
                markable: 1
            };
            console.log(courierChatDetails.id)
            try {
                QB.chat.send(courierChatDetails.id, qbMessage);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const formatTimestamp = (date: Date) => {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    if (!isUserAuthorized && !isSDKInitialized) {
        return <Loading />;
    }

    return (
        <div className="w3-animate-left d-flex flex-column vh-100">
            <div className='bg-primary w-100'>
                <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                    <div
                        onClick={() => navigate(-1)}
                    >
                        <i className="bi bi-arrow-left text-light fw-bolder dark fs-3 ps-3"></i>
                    </div>
                    <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                        <h3 className='w3-center text-light mb-0'>Chat with {courier.fullname.split(' ')[0]}</h3>
                    </div>
                    <div className='me-3'></div>
                </div>
            </div>
            <div className="px-3 flex-grow-1 overflow-auto my-3">
                {messages.length === 0 ? (
                    <div className='mt-3'>
                        {/* <p className='text-center mb-0 text-muted'>No agent in chat</p> */}
                    </div>
                ) : (
                    messages.map((message) => (
                        <div key={message.id} className={`d-flex ${message.isUser ? 'justify-content-end' : 'justify-content-start'} mb-2`}>
                            <div className={`p-2 ${message.isUser ? 'bg-dark text-white user-message' : 'bg-light-gray agent-message'}`} style={{ maxWidth: '70%' }}>
                                <p className="mb-0">{message.text}</p>
                                <small className={`d-block mt-1 ${message.isUser ? 'text-light' : 'text-muted'}`} style={{ fontSize: '0.7rem' }}>
                                    {formatTimestamp(message.timestamp)}
                                </small>
                            </div>
                        </div>
                    ))
                )}
                <div ref={messagesEndRef} />
            </div>
            <div className='container position-sticky bottom-0 mb-3 w-100 bg-white pt-2'>
                <div className='d-flex justify-content-around align-items-center'>
                    <textarea
                        placeholder='Type a message'
                        className='w3-round-xxlarge w-100 message-input p-3 border text-wrap'
                        value={inputMessage}
                        onChange={(e) => setInputMessage(e.target.value)}
                        rows={1}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    />
                    <div className='ms-2'>
                        <div
                            className='send-container w3-circle bg-muted d-flex align-items-center justify-content-center'
                            style={{ width: '50px', height: '50px', cursor: 'pointer' }}
                            onClick={handleSendMessage}
                        >
                            <i className="bi bi-send-fill text-light fs-4"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatWithCourier;
