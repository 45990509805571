import React, { useEffect, useState, useContext } from "react";
import { GoogleMap, Marker, DirectionsRenderer } from "@react-google-maps/api";
import { useMutation, useLazyQuery } from "@apollo/client";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

import UserContext from "../../../Helpers/UserContext";
import Session from "../../../Helpers/Session";
import UserCurrentLocation from "../../../Helpers/UserCurrentLocation";
import { APIResponse } from "../../../Helpers/General";
import { CREATE_NEW_ORDER } from "../../../GraphQL/Mutations";
import { FIND_NEARBY_COURIER } from "../../../GraphQL/Queries";
import NumberToMoney from "../../../Helpers/numberToMoney";
import { BLOCKBUTTON, INPUT } from "../../../Components/Forms";
import { MapStyles } from "../../../Helpers/GoogleMaps";
import DeliveryFlow from "./DeliveryFlow";
import { getImage } from "../../../Helpers/FileHandling";
import InSufficientFunds from "../../../Components/InSufficientFunds";
import { calculateTripFare, isNightTime } from "../../../Helpers/CalculatePrice";

const FindCourier = () => {
    const myContext = useContext(UserContext);
    const user = myContext.user || Session.get("user");
    const [package_details, setPackageDetails] = React.useState(Session.get("package_details"));
    // State variables
    const [amount, setAmount] = useState(0);
    const [pickup, setPickup] = useState(package_details.pickup_address);
    const [dropOff, setDropOff] = useState(package_details.receiver_address);
    const [map, setMap] = useState<google.maps.Map>();
    const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
    const [distance, setDistance] = useState<number>(0);
    const [duration, setDuration] = useState<string>("");
    const [pickupLocation, setPickupLocation] = useState<google.maps.LatLngLiteral>(package_details.pickUpCord);
    const [dropOffLocation, setDropOffLocation] = useState<google.maps.LatLngLiteral>(package_details.dropOffCord);
    const [selectedCourierID, setSelectedCourierID] = useState<number>(0);
    const [couriers, setCouriers] = useState<any[]>([]);
    const [userLocation, setUserLocation] = useState({ latitude: 0, longitude: 0 });
    const [step, setStep] = useState(Session.get("deliveryStep") || 0);
    const [courierDurations, setCourierDurations] = useState<Record<number, string>>({});
    const COST = JSON.parse(Session.getCookie("config")).cost;
    const [inSuffientFunds, SetInSuffientFunds] = useState(false);
    // User location handler
    const handleUserLocation = async () => {
        const miiLocation = (await UserCurrentLocation()) || { latitude: 0, longitude: 0 };
        setUserLocation(miiLocation);
    };

    // Places autocomplete handlers
    const {
        ready: pickupReady,
        value: pickupValue,
        suggestions: { status: pickupStatus, data: pickupData },
        setValue: setPickupValue,
        clearSuggestions: clearPickupSuggestions,
    } = usePlacesAutocomplete();

    const {
        ready: dropoffReady,
        value: dropoffValue,
        suggestions: { status: dropoffStatus, data: dropoffData },
        setValue: setDropoffValue,
        clearSuggestions: clearDropoffSuggestions,
    } = usePlacesAutocomplete();

    useEffect(() => {
        setPickupValue(pickup, false);
        setDropoffValue(dropOff, false);
    }, [pickup, dropOff, setPickupValue, setDropoffValue]);

    const calculateCourierDurations = async (couriers: any[], pickupLocation: google.maps.LatLngLiteral) => {
        const directionsService = new google.maps.DirectionsService();

        for (let courier of couriers) {
            let courierLocation;
            try {
                courierLocation = JSON.parse(courier.current_location);
            } catch (error) {
                courierLocation = courier.current_location
            }
            try {
                setCourierDurations(prev => ({ ...prev, [courier.user_id]: "Calculating..." }));
                directionsService.route({
                    origin: courierLocation,
                    destination: pickupLocation,
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                        setCourierDurations(prev => ({
                            ...prev,
                            [courier.user_id]: result.routes[0].legs[0].duration?.text || "Unknown"
                        }));
                        console.log(`Duration for courier ${courier.user_id}:`, result.routes[0].legs[0].duration?.text);
                    }
                });
            } catch (error) {
                console.error(`Error calculating duration for courier ${courier.user_id}:`, error);
                setCourierDurations(prev => ({ ...prev, [courier.user_id]: "Error" }));
            }
        }

    };

    // Queries and Mutations
    const [findNearbyCourier, { loading }] = useLazyQuery(FIND_NEARBY_COURIER, {
        variables: {
            lat: pickupLocation?.lat || userLocation.latitude,
            lng: pickupLocation?.lng || userLocation.longitude,
        },
        onCompleted(data) {
            if (data?.findNearbyCourier.length > 0) {
                console.log(data.findNearbyCourier);
                setCouriers(data.findNearbyCourier);
                calculateCourierDurations(data.findNearbyCourier, pickupLocation);
            } else {
                Session.showAlert({ str: "No courier found nearby, try again later", type: "error" });
                return;
            }
        },
        onError(error) {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: "error" });
        },
    });
    const handleStep = (newStep: number) => {
        setStep(newStep);
        Session.set("deliveryStep", newStep);
    }
    const [createNewOrder] = useMutation(CREATE_NEW_ORDER, {
        variables: {
            packageName: package_details?.package_name,
            packageQuantity: package_details?.package_quantity,
            pickupAddress: pickup,
            receiverName: package_details?.receiver_name,
            receiverAddress: dropOff,
            receiverPhone: package_details?.receiver_phone,
            amount,
            distance,
            duration,
            riderId: selectedCourierID,
        },
        onCompleted(data) {
            if (data.createNewOrder) {
                handleStep(1);
                Session.set("selectedCourier", couriers.filter((courier: any) => courier.user_id === selectedCourierID));
                Session.set("package_details", Object.assign({}, package_details, data.createNewOrder));
            }
        },
        onError(error) {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: "error" });
        },
    });

    // Location selection handlers
    const handlePickUpSelect = async (address: string) => {
        setPickupValue(address, false);
        clearPickupSuggestions();
        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setPickup(address);
            setPickupLocation({ lat, lng });
            map?.panTo({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleDropOffSelect = async (address: string) => {
        setDropoffValue(address, false);
        clearDropoffSuggestions();
        try {
            const results = await getGeocode({ address });
            const { lat, lng } = await getLatLng(results[0]);
            setDropOff(address);
            setDropOffLocation({ lat, lng });
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleUseCurrentLocation = async () => {
        handleUserLocation();
        if (userLocation.latitude && userLocation.longitude) {
            try {
                const results = await getGeocode({ location: { lat: userLocation.latitude, lng: userLocation.longitude } });
                if (results) {
                    const address = results[0].formatted_address;
                    setPickup(address);
                    setPickupValue(address, false);
                    setPickupLocation({ lat: userLocation.latitude, lng: userLocation.longitude });
                    clearPickupSuggestions();
                    map?.panTo({ lat: userLocation.latitude, lng: userLocation.longitude });
                }
            } catch (error) {
                console.error("Error: ", error);
            }
        }
    };

    const handleCreateOrder = async () => {
        if (amount > user?.balance) {
            SetInSuffientFunds(true);
            return;
        }
        if (amount < 1 || selectedCourierID < 1 || !pickup || !dropOff) {
            Session.showAlert({ str: "Please complete all fields before confirming your order", type: "error" });
            return;
        }
        await createNewOrder();
    };

    // Directions handler
    useEffect(() => {
        if (pickupLocation && dropOffLocation) {
            const directionsService = new google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: pickupLocation,
                    destination: dropOffLocation,
                    travelMode: google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === google.maps.DirectionsStatus.OK && result) {
                        setDirections(result);
                        const route = result.routes[0];
                        const leg = route.legs[0];
                        if (leg) {
                            setDistance(leg.distance?.value || 0);
                            const calPrice = calculateTripFare(COST, { distanceKM: (Math.round(leg.distance?.value || 0) / 1000), isNight: isNightTime(), surgeMultiplier: 1, waitTimeMinutes: 1 });
                            setAmount(calPrice);
                            // setAmount((Math.round(leg.distance?.value || 0) / 1000) * COST.distanceKMMultiplier);
                            setDuration(leg.duration?.text || "");
                        }
                    } else {
                        console.error(`Directions request failed: ${status}`);
                    }
                }
            );
        }
    }, [pickupLocation, dropOffLocation]);

    // Google Maps center
    const center = {
        lat: pickupLocation?.lat || userLocation.latitude || 6.6018,
        lng: pickupLocation?.lng || userLocation.longitude || 3.3515,
    };

    return (
        <>
            <div className="d-flex position-relative flex-column" style={{ height: "100svh", width: "100svw" }}>
                <div className="position-absolute w-100 h-100">
                    <GoogleMap
                        mapContainerStyle={{ height: "100svh", width: "100svw" }}
                        center={center}
                        zoom={15}
                        clickableIcons={false}
                        options={{
                            styles: MapStyles,
                            streetViewControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            zoomControl: false,
                            gestureHandling: "greedy",
                        }}
                        onLoad={(map) => setMap(map)}
                    >
                        {pickupLocation && <Marker position={pickupLocation} />}
                        {dropOffLocation && <Marker position={dropOffLocation} />}
                        {directions && <DirectionsRenderer directions={directions} options={{ polylineOptions: { strokeColor: "#FF6600", strokeWeight: 5 } }} />}
                    </GoogleMap>
                </div>
            </div>
            {couriers.length === 0 && step === 0 && (
                <div
                    className={'brand-modal-map w3-animate-bottom d-flex'}
                >
                    <div className='w-100 brand-modal-content bg-light pt-3 px-3 position-relative'
                        style={{ minHeight: "50vh", overflowY: "scroll" }}
                    >
                        {loading && (
                            <div className="mb-2">
                                <h3 className="fs-5 fw-bold my-0 pb-2">Looking for nearby Courier</h3>
                                <div className="progress" style={{ height: "4px", borderRadius: "2px" }}>
                                    <div className="progress-bar bg-primary progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} style={{ width: "100%", height: "4px" }}></div>
                                </div>
                            </div>
                        )}

                        <p className="mb-0">Delivery Fee</p>
                        <h3 className="my-0 fs-3 fw-bold mb-4">&#8358;{NumberToMoney(amount)}</h3>
                        <p className="mb-0">Delivery Address</p>
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center flex-column pt-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                    <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                </svg>
                                <i className="bi bi-three-dots-vertical text-muted fs-3"></i>
                                <i className="bi bi-geo-alt-fill text-primary fs-3"></i>
                            </div>
                            <div className="d-flex align-items-center flex-column w-100 ms-2">
                                <div className="w-100 mb-2 position-relative">
                                    <INPUT
                                        value={pickupValue}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPickupValue(e.target.value)}
                                        disabled={!pickupReady}
                                        placeholder="Enter pick up location"
                                        className="form-control w-100"
                                    />
                                    {pickupStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            <li className="list-group-item text-primary" onClick={handleUseCurrentLocation}>
                                                Use Current Location
                                            </li>
                                            {pickupData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handlePickUpSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <div className="w-100">
                                    <INPUT
                                        value={dropoffValue}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDropoffValue(e.target.value)}
                                        disabled={!dropoffReady}
                                        placeholder="Enter drop off location"
                                        className="form-control w-100"
                                    />
                                    {dropoffStatus === "OK" && (
                                        <ul className="list-group position-absolute w-100 z-2">
                                            {dropoffData.map((suggestion) => (
                                                <li
                                                    key={suggestion.place_id}
                                                    onClick={() => handleDropOffSelect(suggestion.description)}
                                                    className="list-group-item"
                                                >
                                                    {suggestion.description}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-3 bg-light">
                            <BLOCKBUTTON
                                className={"btn-primary mt-2 mb-3 " + ((loading) ? "d-none" : "")}
                                onClick={() => findNearbyCourier()}
                            >
                                Find Courier
                            </BLOCKBUTTON>
                        </div>


                    </div>
                </div>
            )}

            {couriers && couriers.length > 0 && step === 0 && (
                <>
                    <div className={'brand-modal-map w3-animate-bottom d-flex'}>
                        <div className='w-100 brand-modal-content bg-light'>
                            <div className='px-2 pt-3'>
                                <h3 className='fw-bold fs-4 my-0 pb-3'> Select Courier</h3>
                                <div>
                                    {couriers.map((courier: Record<string, any>) => (
                                        <div key={courier.user_id} className={'d-flex align-items-center p-2 justify-content-between border border-2 w3-round-large mb-2 ' + (courier.user_id === selectedCourierID ? 'border-primary pending' : 'border-muted bg-light shadow-none')}
                                            onClick={() => setSelectedCourierID(courier.user_id)}
                                        >
                                            <div className="d-flex align-items-center">
                                                <div className='rounded-circle d-flex align-items-center justify-content-center'
                                                    style={{ width: '50px', height: '50px' }}
                                                >
                                                    <img src={getImage(courier.photo)} alt={courier.fullname} className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow" />
                                                </div>
                                                <div className="d-flex flex-column align-items-start ms-2">
                                                    <p className="my-0 fw-bold fs-5">{courier.fullname.split(" ")[0]}</p>
                                                    <p className="mb-0">{courierDurations[courier.user_id] || "Calculating..."} away</p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-start flex-column ">
                                                <p className="mb-0 px-2 py-1 text-primary w3-round-large bg-white">{courier.successful_orders} Deliveries</p>
                                                <div className="d-flex align-items-center ">
                                                    <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                                    <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                                    <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                                    <i className="bi bi-star-fill text-warning fs-4 mx-1"></i>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>
                                <BLOCKBUTTON
                                    className={"btn-primary mt-2 mb-3 " + ((loading) ? "d-none" : "")}
                                    onClick={handleCreateOrder}
                                    disabled={selectedCourierID < 1}
                                >
                                    Request Courier
                                </BLOCKBUTTON>

                            </div>
                        </div>
                    </div>
                    {inSuffientFunds && <InSufficientFunds info="Fund your wallet in order to proceed." />}
                </>

            )}
            {step === 1 && <DeliveryFlow setDirections={setDirections} distance={distance} duration={duration} setDistance={setDistance} setDuration={setDuration} amount={amount} setAmount={setAmount} />}

        </>
    );
};

export default FindCourier;