import { gql } from '@apollo/client';


export const LOGIN = gql`
mutation Login($email: String, $token: String) {
  login(email: $email, token: $token) {
    accessToken
    refreshToken
  }
}
`;

export const LOGOUT = gql`
mutation Mutation {
  logout
}
`;

export const SEND_TOKEN = gql`
mutation SendLoginToken($email: String) {
  sendLoginToken(email: $email)
}
`;

export const REGISTER = gql`
mutation Register($fullname: String, $email: String, $phoneNumber: String, $photo: String, $category: String) {
  register(fullname: $fullname, email: $email, phone_number: $phoneNumber, photo: $photo, category: $category) {
    accessToken
    refreshToken
  }
}
`;
export const VALIDATE_EMAIL = gql`
mutation ValidateToken($email: String, $token: String) {
  validateToken(email: $email, token: $token)
}
`;

export const UPDATE_ACCEPTED_TERMS = gql`
mutation UpdateTermsAccepted($email: String) {
  updateTermsAccepted(email: $email)
}
`;

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($fullname: String, $phoneNumber: String, $photo: String) {
  updateProfile(fullname: $fullname, phone_number: $phoneNumber, photo: $photo) {
    id
    fullname
    email
    phone
    photo
    balance
    category
    successful_orders
    rating
    created_at
  }
}
`;

export const WITHDRAW_FUNDS = gql`
mutation WithdrawFunds($amount: Float, $accountNumber: String, $accountName: String, $bankName: String, $reference: String) {
  withdrawFunds(amount: $amount, account_number: $accountNumber, account_name: $accountName, bank_name: $bankName, reference: $reference)
}
`;

export const UPDATE_ORDER_LOCATION = gql`
mutation UpdateOrderLocation($orderId: Int, $receiverAddress: String, $distance: Float, $duration: String, $amount: Float) {
  updateOrderLocation(order_id: $orderId, receiver_address: $receiverAddress, distance: $distance, duration: $duration, amount: $amount) {
    id
    order_code
    user_id
    sender_name
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    distance
    duration
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
  }
}
`;

export const FUND_WALLET = gql`
mutation FundWallet($amount: Float) {
  fundWallet(amount: $amount)
}
`;

export const ADD_CARD = gql`
mutation AddCard($cardNumber: String, $cardName: String, $expiryDate: String, $cvv: String, $cardProvider: CardProvider) {
  addCard(card_number: $cardNumber, card_name: $cardName, expiry_date: $expiryDate, cvv: $cvv, card_provider: $cardProvider) {
    id
    card_number
    card_name
    expiry_date
    card_provider
    cvv
    created_at
  }
}
`;

export const CANCEL_ORDER = gql`
mutation CancelOrder($orderId: Int, $cancellationReason: String) {
  cancelOrder(order_id: $orderId, cancellation_reason: $cancellationReason)
}`;
export const CREATE_NEW_ORDER = gql`
mutation CreateNewOrder($packageName: String, $packageQuantity: Int, $pickupAddress: String, $receiverName: String, $receiverAddress: String, $receiverPhone: String, $amount: Float, $riderId: Int, $duration: String, $distance: Float) {
  createNewOrder(package_name: $packageName, package_quantity: $packageQuantity, pickup_address: $pickupAddress, receiver_name: $receiverName, receiver_address: $receiverAddress, receiver_phone: $receiverPhone, amount: $amount, rider_id: $riderId, duration: $duration, distance: $distance) {
    id
    order_code
    user_id
    sender_name
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    distance
    duration
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
  }
}
`;