/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import UserContext from "../../Helpers/UserContext";
import numberToMoney from "../../Helpers/numberToMoney";
import Menu from "../../Components/Menu";
import Session from "../../Helpers/Session";
import { useQuery } from '@apollo/client';
import { GET_TRANSACTIONS } from "../../GraphQL/Queries";
import { APIResponse } from '../../Helpers/General';
import FormatDate from '../../Helpers/FormatDate';

const Wallet = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [hideBalance, setHideBalance] = React.useState<boolean>(false);
    const [balance, setBalance] = React.useState<number>(user?.balance || 0.00);
    const [offset, setOffset] = React.useState(0);
    const [transactions, setTransactions] = React.useState([]);
    const name = user?.fullname;

    const { refetch, loading } = useQuery(GET_TRANSACTIONS, {
        fetchPolicy: 'network-only',
        variables: {
            offset: offset
        },
        onCompleted(data) {
            setTransactions(data.getAllTransactions);
        },
        onError(error) {
            Session.showAlert({ str: error.message, type: 'error' });
            APIResponse(error);
            return;
        },
    });

    const toggleBalanceVisibility = () => {
        setHideBalance(!hideBalance);
    };

    const formatTransaction = (transaction: Record<string, any>) => {
        if (transaction.status === 'Withdrawn' || transaction.status === 'Paid') {
            return (
                <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large" key={transaction.id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-down-left fs-3 text-negative"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-negative fs-6">&#8358;&nbsp;{numberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        } else if (transaction.status === 'Deposited') {
            return (
                <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large" key={transaction.id}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-up-right fs-3 text-success"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-success fs-6">&#8358;&nbsp;{numberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
            <div className="w3-animate-left">
                <div className='bg-primary w-100 pb-3' style={{ borderRadius: "0 0 16px 16px" }}>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="#">
                                {/* <i className="bi bi-arrow-left text-light fw-bolder"></i> */}
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'> </h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                    <div className="my-3">
                        <div>
                            <p className="text-center mb-0 text-light">Wallet Balance</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <h2 className="text-center text-light my-0 fw-normal">
                                {hideBalance ? "*".repeat(numberToMoney(+balance.toFixed(2)).toString().length) : (<span>&#8358;{numberToMoney(+balance.toFixed(2))}</span>)}
                            </h2>
                            <i
                                className={`bi ${hideBalance ? "bi-eye" : "bi-eye-slash"} ms-2 text-light fs-2`}
                                onClick={toggleBalanceVisibility}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </div>
                    </div>
                    <div className="px-3 w-100">
                        <div className="d-flex justify-content-around align-items-center">
                            <Link to={"/fund-account"}>
                            <div className=" bg-light w3-round-large w3-center px-3 py-1">
                                <i className="bi bi-wallet2 fs-1 text-primary fw-bold"></i>
                                <p className="mb-0 text-center text-primary fw-normal text-nowrap">Fund Wallet</p>
                            </div>
                            </Link>
                            <Link to="/withdraw">
                                <div className=" bg-light w3-round-large w3-center px-4 py-1">
                                    <i className="bi bi-credit-card fs-1 text-primary fw-bold"></i>
                                    <p className="mb-0 text-center text-primary fw-normal">Withdraw</p>
                                </div>
                            </Link>
                            <Link to="/transaction">
                                <div className=" bg-light w3-round-large w3-center px-3 py-1">
                                    <i className="bi bi-currency-exchange fs-1 text-primary fw-bold"></i>
                                    <p className="mb-0 text-center text-primary fw-normal">Transactions</p>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
                    <div className="container mt-3 px-2">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <h3 className="my-0 ps-2 fw-bold">Transaction History</h3> <i className="bi bi-funnel text-dark fs-2 pe-3"></i>
                        </div>
                        {transactions.slice(0, 10).map((transaction: Record<string, any>) => formatTransaction(transaction))}
                    </div>
                    <Menu />
            </div>
        </>
    )
}


export default Wallet;