import { gql } from '@apollo/client';


export const CURRENT_USER = gql`
query CurrentUser {
  currentUser {
    id
    fullname
    email
    phone
    photo
    balance
    category
    successful_orders
    chat_details
    rating
    created_at
  }
}
`;

export const GET_CONFIG = gql`
query Query {
  getConfig
}
`;

export const GET_ALL_NOTIFICATION = gql`
query GetAllNotifications($offset: Int) {
  getAllNotifications(offset: $offset) {
    id
    user_id
    message
    created_at
  }
}
`;

export const GET_ORDERS = gql`
query GetAllOrders($offset: Int) {
  getAllOrders(offset: $offset) {
    id
    order_code
    user_id
    package_name
    package_quantity
    pickup_address
    receiver_name
    receiver_address
    receiver_phone
    rider_id
    rider_name
    status
    amount
    cancellation_reason
    created_at
    delivered_at
  }
}
`;

export const GET_TRANSACTIONS = gql`
query GetAllTransactions($offset: Int) {
  getAllTransactions(offset: $offset) {
    id
    user_id
    order_id
    email
    code
    amount
    remarks
    status
    created_at
  }
}
`;
export const GET_ALL_CARDS = gql`
query GetAllCards {
  getAllCards {
    id
    card_number
    card_name
    expiry_date
    card_provider
    cvv
    created_at
  }
}
`;
export const FIND_NEARBY_COURIER = gql`
query Query($lat: Float!, $lng: Float!) {
  findNearbyCourier(lat: $lat, lng: $lng)
}
`;
export const GET_ORDER_STATUS = gql`
query Query($orderId: Int) {
  getOrderStatus(order_id: $orderId)
}
`;